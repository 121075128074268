import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/Layout';

const NotFound = () => {
  const { t, originalPath, i18n } = useI18next();

  return (
    <Layout t={t} originalPath={originalPath} i18n={i18n}>
      404
    </Layout>
  );
};

export default NotFound;
